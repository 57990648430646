import React, { Component, useCallback, useEffect, useState } from 'react'
import { BASE } from './../basedata/constants'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'
import './OnboardCard.sass'
import BiometricComponent from './BiometricComponent/BiometricComponent'
import SuccessHandler from './StatesComponents/SuccessHandler'
import ErrorHandler from './StatesComponents/ErrorHandler'
import LoadingHandler from './StatesComponents/LoadingHandler'
import MobileHandler from './StatesComponents/MobileHandler'
import ErrorCapHandler from './StatesComponents/ErrorCapHandler'
import ErrorGlassesHandler from './StatesComponents/ErrorGlassesHandler'
import ErrorInvalidHandler from './StatesComponents/ErrorInvalidHandler'
import DocumentAutoCapture from './BiometricComponent/components/DocumentAutoCapture'
import { DocumentCallback, DocumentComponentData } from '@innovatrics/dot-document-auto-capture'
import DocumentTypeErrorHandler from './StatesComponents/DocumentTypeHandler'
import PersonErrorHandler from './StatesComponents/PersonErrorHandler'
import PhotoResult from './BiometricComponent/components/PhotoResult'
import styles from '../Biometric/BiometricComponent/styles/index.module.css'
import LoadingHandlerValidator from './StatesComponents/LoadingHandlerValidator'
import DocumentPassportMessage from './StatesComponents/DocumentPassportMessage'

interface Props {
  token: any
  choosenDocType: string
  onResponse: (response: any) => void
}

function DocumentOnboard({ token, onResponse, choosenDocType }: Props) {


  const [photoSide, setPhotoSide] = useState<string>('Frente')


  const getByLabelText = (choosedDocType:any):string => {
    switch(choosedDocType){
      case 'CNH':
        return 'da CNH'
      case 'RG':
        return 'do RG'
      case 'PASSAPORTE':
        return 'do Passaporte'
      default:
        return "do Documento" 
    }
  }

  const [photoState, setphotoState] = useState<string>('')

  const [photoUrl, setPhotoUrl] = useState<string>()
  const [blobImage, setBlobImage] = React.useState({})

  

  const [docType, setDocType] = useState<string>(choosenDocType)
  const [docLabel, setDocLabel] = useState<string>(getByLabelText(choosenDocType))


  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }

    window.gtag('event', 'Onboarding: Document Select', {
      'event_category': 'onboarding',
      'event_label': 'choose_document',
      'value': 1
    });
  }, [])

  const handlePhotoTaken = (image: Blob, data: DocumentComponentData) => {
    const imageUrl = URL.createObjectURL(image)
    setBlobImage(image)
    setPhotoUrl(imageUrl)
  }

  const handleFaceCapturePhotoTaken: DocumentCallback = (image, data) => {
    // onResponse('loading')
    handlePhotoTaken(image, data)
  }

  const confirmCapture = async (image: any) => {
    // onResponse('loading')

    setphotoState('loading')
    // console.log(token)

    if((docType === 'CNH' && photoSide === 'Frente') || 
    docType === 'RG' && photoSide === 'Frente'){

      const pacoca:any = await saveDocument(image)
      
      setTimeout(() => {
        if(pacoca){
          setphotoState('')
          setPhotoSide('Verso')
          setPhotoUrl('')
          setBlobImage('')
          return false
        }
      }, 300);

    }else{

      const pacoca:any = await saveDocument(image)

      setTimeout(() => {
        console.log(pacoca)
        if(pacoca){
          validateData()
          return false
        }
      }, 300);
      
    }
  }


  const saveDocument = async (image: Blob) => {

    const newImage = await blobToBase64(image)
    console.log('aoooo')

    if(!token){
      token = `${window.sessionStorage.getItem('token')}`
    }

    return fetch(`${BASE.api.base_url}${BASE.api.create_document}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        originToken: token,
        side: photoSide === 'Frente' ? 'documentFront':'documentBack',
        type: docType,
        imageBase64: newImage,
      })
    })
      .then((resg) => resg.json())
      .then((responseSave) => {

        if (responseSave.status === 'userNotFound') {
          return false
        }
          return true
      })
      .catch((error) => {
        return false
      })
  }


  let tries = 0
  const validateData = async () => {


    tries++;

    try {

    return fetch(`${BASE.api.base_url}${BASE.api.validate_document}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        originToken: token
      })
    })
      .then((resg) => resg.json())
      .then((responseSave) => {

        window.gtag('event', 'Onboarding: Document Captured', {
          'event_category': 'onboarding',
          'event_label': 'document_captured',
          'value': 1
        });

        return setphotoState('waitingValidation')

      }).catch((error) => {
        console.log('oi')
        window.gtag('event', 'Doc Error: Idwall', {
          'event_category': 'document_capture',
          'event_label': 'idwall_error',
          'value': 1
        });
  
  
        if(tries >= 5){
  
          window.gtag('set', {
            'document_error_token': JSON.stringify(token),
          });
  
          window.gtag('event', 'Doc Error: Idwall', {
            'event_category': 'document_capture',
            'event_label': 'idwall_explode',
            'value': 1
          });
          window.location.reload()
        }else{
          setTimeout(() => {
            validateData()
          }, 3000);
        }
      })
    }catch(e){
      console.log('oi')
      window.gtag('event', 'Doc Error: Idwall', {
        'event_category': 'document_capture',
        'event_label': 'idwall_error',
        'value': 1
      });


      if(tries >= 5){

        window.gtag('set', {
          'document_error_token': JSON.stringify(token),
        });

        window.gtag('event', 'Doc Error: Idwall', {
          'event_category': 'document_capture',
          'event_label': 'idwall_explode',
          'value': 1
        });
        window.location.reload()
      }else{
        setTimeout(() => {
          validateData()
        }, 3000);
      }
    }
  }


  // useEffect(() => {
  //   const currentDocType = window.sessionStorage.getItem('docType') || ''
  //   setDocType(currentDocType)
  // }, [docType])
  return (
    <>
      <div className={`${photoState} card proccess-card documentContainer`}>
        {photoState === 'success' ? (
          <>
            <SuccessHandler />
          </>
        ) : photoState === 'error' ? (
          <ErrorHandler />
        ) : photoState === 'invalidDoc' ? (
          <DocumentTypeErrorHandler onResponse={handleResponse} />
        ) : photoState === 'loading' ? (
          <LoadingHandler />
        ) : photoState === 'waitingValidation' ? (
          <LoadingHandlerValidator />
        ) : photoState === 'passportValidation' ? (
          <DocumentPassportMessage onResponse={handleResponse} />
        ) : photoState === 'analysis' ? (
          <PersonErrorHandler onResponse={handleResponse} />
        ) : photoState === 'cap' ? (
          <ErrorCapHandler onResponse={handleResponse} />
        ) : isMobile || photoState === 'mobile' ? (

          <>
          <h1>
            <span>{docType === 'PASSAPORTE' ? 'Folha rosto':photoSide} {docLabel}</span>
          </h1>
          <hr />
          <div className={`${styles.app} medium-frame`}>
          {/* <DocumentAutoCapture 
            onPhotoTaken={handleFaceCapturePhotoTaken}
            onError={handleError}
            onBackClick=
          /> */}
          {photoUrl ? (
              <PhotoResult
                confirmCapture={confirmCapture}
                photoUrl={photoUrl}
                blobImage={blobImage}
              />
            ):<DocumentAutoCapture onPhotoTaken={handleFaceCapturePhotoTaken} onError={function (error: Error): void {
              // throw new Error('Function not implemented.')
          } } onBackClick={function (): void {
              // throw new Error('Function not implemented.')
          } }/>}
          </div>

          </>
        ) : (
          <MobileHandler />
        )}
      </div>
      <ul className="step-counter">
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
      </ul>
    </>
  )
}

export default DocumentOnboard
